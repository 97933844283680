import React from "react";
import { Redirect, Route } from "react-router-dom";
import { HydraAdmin, hydraDataProvider as baseHydraDataProvider, fetchHydra as baseFetchHydra, hydraSchemaAnalyzer } from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import authProvider from "./authProvider";
import jwt from "jsonwebtoken"

const entrypoint = process.env.REACT_APP_API_URL;
const fetchHeaders = { Authorization: `Bearer ${window.localStorage.getItem("token")}` };
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
});
const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) })
    .then(
        ({ api }) => ({ api }),
        (result) => {
            switch (result.status) {
                case 401:
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [
                            <Route path="/" render={() => {
                                let current = window.localStorage.getItem("token");
                                let decoded = jwt.decode(current)
                                if(!decoded){
                                    return <Redirect to="/login" />
                                }
                                if (decoded.exp < (new Date().getTime() / 1000) - 10) {
                                    window.localStorage.removeItem("token")
                                    return <Redirect to="/login" />
                                }
                                
                                return window.location.reload();
                                //return window.localStorage.getItem("token") ? window.location.reload() : <Redirect to="/login" />
                                /*if(window.localStorage.getItem("token")){
                                    window.localStorage.removeItem("token")
                                }
                                return <Redirect to="/login" />*/
                            }} />
                        ],
                    });

                default:
                    return Promise.reject(result);
            }
        },
    );
const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);

export default () => (
    <HydraAdmin
        apiDocumentationParser={apiDocumentationParser}
        dataProvider={ dataProvider }
        authProvider={ authProvider }
        entrypoint={ entrypoint }
        schemaAnalyzer={hydraSchemaAnalyzer()}
    />
);